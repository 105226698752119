<template>
<div id="cdnfiles">
  <b-field class="markdown-body">
    <help-cdn-files />
  </b-field>
  <b-field horizontal label="CDN文件夹">
    <b-select v-if="siteUrl" v-model="cdnPath" placeholder="选择一个文件夹" @input="refresh">
      <option v-for="(v, k) in cdnDirs" :key="k" :value="v">{{k}}</option>
    </b-select>
    <div>{{listUrl}}</div>
    <button v-if="siteUrl" class="button is-success" @click="refresh">刷新列表</button>
  </b-field>
  <b-field v-if="siteUrl" horizontal label="上传文件">
    <div class="columns">
      <div class="column">
        <b-upload v-model="dropFiles" multiple drag-drop>
          <section class="section">
            <div class="content has-text-centered">
              <p>
                  <b-icon
                      icon="upload"
                      size="is-large">
                  </b-icon>
              </p>
              <p>拖动文件到此或点击上传</p>
              </div>
            </section>
        </b-upload>
      </div>
      <div class="column tags">
        <span v-for="(file, index) in dropFiles"
            :key="index"
            class="tag is-primary" >
            {{file.name}}
            <button class="delete is-small"
                type="button"
                @click="deleteDropFile(index)">
            </button>
        </span>
      </div>
      <div class="column">
        <button :disabled="dropFiles.length === 0 || !cdnPath" class="button is-warning" @click="onFileUpload">上传</button>
      </div>
    </div>
  </b-field>
  <b-field v-if="siteUrl" horizontal label="使用 HTTPS">
    <b-tooltip label="小程序允许使用 HTTP，小游戏只能使用 HTTPS。在可能的情况下，应尽量使用 HTTP 以节省 CDN 费用。">
      <b-switch v-model="useHTTPS">HTTPS</b-switch>
    </b-tooltip>
  </b-field>
  <b-table :data="files">
    <b-table-column #default="props" field="name" label="name">
      {{ props.row.name }}
    </b-table-column>
    <b-table-column #default="props" field="url" label="url">
      {{dSiteUrl}}{{cdnPath}}{{ props.row.name }}
    </b-table-column>
    <b-table-column #default="props" field="size" label="size">
      {{ toHumanSize(props.row.size) }}
    </b-table-column>
    <b-table-column #default="props" field="action" label="action">
      <div class="buttons">
        <a :href="dSiteUrl+cdnPath+props.row.name" target="_blank" class="button is-success">查看</a>
        <button class="button is-danger" @click="onFileDel(props.row)">删除</button>
      </div>
    </b-table-column>
  </b-table>
</div>
</template>

<script>
// 管理 CDN 文件
import HelpCdnFiles from '@/help/cdnfiles.md'

// eslint-disable-next-line
const httpsRe = /https?:\/\//i

export default {
  name: 'cdnfiles',
  components: { HelpCdnFiles },
  mounted () {
    this.getCDNDir()
  },
  data () {
    return {
      files: [],
      dropFiles: [],
      cdnDirs: null,
      cdnPath: null,
      siteUrl: null,
      useHTTPS: true
    }
  },
  computed: {
    listUrl () {
      if (this.siteUrl === null) {
        return '没有配置 CDN 文件夹，请在 regional 中配置。参考 5094 单挑篮球'
      }
      if (this.cdnPath) {
        return this.siteUrl + this.cdnPath
      }
      return '请选择 CDN 文件夹'
    },
    dSiteUrl () {
      if (this.useHTTPS) {
        return this.siteUrl.replace(httpsRe, 'https://')
      }
      return this.siteUrl.replace(httpsRe, 'http://')
    }
  },
  methods: {
    clear () {
      this.files = []
      this.dropFiles = []
    },
    toHumanSize (size) {
      if (size > 1024) return `${parseInt(size / 1024)} KB`
      return `${size} Byte`
    },
    deleteDropFile (index) {
      this.dropFiles.splice(index, 1)
    },
    async getCDNDir () {
      try {
        const dirdata = await this.mjp.get('/util/cdn/getdir/')
        this.cdnDirs = dirdata.dir
        this.siteUrl = dirdata.siteurl
        if (this.siteUrl !== null && this.cdnDirs !== null) {
          for (const k in this.cdnDirs) {
            this.cdnPath = this.cdnDirs[k]
            break
          }
          this.refresh()
        }
      } catch (error) {
        this.siteUrl = null
        this.cdnDirs = null
        this.cdnPath = null
        console.error(error)
      }
    },
    async refresh () {
      this.clear()
      try {
        const rdata = await this.mjp.get('/util/cdn/list/', { dir: this.cdnPath })
        this.files = rdata.files
        this.cdnPath = rdata.path
      } catch (error) {
        console.error(error)
      }
    },
    async onFileUpload (evt) {
      // eslint-disable-next-line
      let asciiOnly = /[\w\-]+\.\w+/
      for (const f of this.dropFiles) {
        if (!asciiOnly.test(f.name)) {
          this.hub.alert('文件名不允许为中文！')
          return
        }
      }
      try {
        const formData = new FormData()
        for (const f of this.dropFiles) {
          formData.append(f.name, f)
        }
        formData.append('dir', this.cdnPath)
        await this.mjp.post2({ url: '/util/cdn/upload/', data: formData })
        this.refresh()
      } catch (error) {
        console.error(error)
      }
    },
    onFileDel (row) {
      const file = `${this.cdnPath}${row.name}`
      this.$buefy.dialog.confirm({
        title: '删除文件',
        message: `确定要删除 ${file} 吗？`,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.mjp.get('/util/cdn/delete/', { file })
            this.refresh()
          } catch (error) {
            console.error(error)
          }
        }
      })
    }
  }
}
</script>

<style>
#cdnfiles {
  padding: 12px;
}
</style>
